import { PictureElementList, IPictureElementList } from '../PictureElementList'

export interface ILogo {
  logo: IPictureElementList
  onClick?: () => void
}

export function Logo({ logo, onClick }: ILogo): JSX.Element {
  return (
    <>
      <div className="Logo_container flex" onClick={onClick}>
        <PictureElementList elementList={logo.elementList} lazyload />
      </div>

      <style jsx>{`
        .Logo_container {
          position: relative;
          width: 63px;
          height: 100%;
        }

        .Logo_container :global(picture) {
          flex: 1;
          display: flex;
        }

        .Logo_container :global(img) {
          flex: 1;
          border-radius: 50px;
          max-width: 100%;
          max-height: 100%;
          object-fit: cover;
        }
      `}</style>
    </>
  )
}
